import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { apiGet } from '~src/api/client'
import { useModel, useRootState } from '~src/hooks/store'
import useWindowSize from '~src/hooks/useWindowResize'
import { updateBasicConfiguration } from '~src/store/configuration/actions'
import { breakpoints } from '~src/utils/breakpoints'
import { currencyFormatter, numberFormatter } from '~src/utils/number-formatter'
import {
  DEFAULT_DOWN_PAYMENT_PERCENTAGE,
  DEFAULT_FINAL_PAYMENT_PERCENTAGE,
  findFinancialConfig,
  findPaymentPlan,
  getMonthlyCreditPayment,
} from '~src/utils/price'
import { OccasionDisclaimer } from './OccasionDisclaimer'

const FinanceTable = ({
  showTableHorizontal,
  apiRootUrl,
}: {
  showTableHorizontal?: boolean
  apiRootUrl: string
}) => {
  const model = useModel()
  const {
    configuration,
    api: { occasion, financialProducts },
  } = useRootState()
  const windowSize = useWindowSize()

  const totalPrice = Number(occasion.price_online)
  const downPayment =
    configuration.basic.downPayment > 0
      ? configuration.basic.downPayment
      : Math.floor(totalPrice * DEFAULT_DOWN_PAYMENT_PERCENTAGE)

  const credit = totalPrice - downPayment

  const finalPayment =
    configuration.basic.finalPayment > 0
      ? configuration.basic.finalPayment
      : credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup, model)

  const financialConfig = product && findFinancialConfig(credit, product, configuration)
  const downPaymentPercentage = ((downPayment / totalPrice) * 100).toFixed(1)
  const finalPaymentPercentage = ((finalPayment / credit) * 100).toFixed(1)

  if (!financialConfig) {
    return null
  }

  const monthlyPayment = getMonthlyCreditPayment(
    credit,
    Number(financialConfig.interest_rate) / 100,
    finalPayment,
    configuration.basic.durationMonths
  )

  const totalCreditPayment = monthlyPayment * configuration.basic.durationMonths + finalPayment

  const tableData = [
    [
      <>
        Contant
        <wbr />
        prijs
      </>,
      currencyFormatter.format(totalPrice),
    ],
    ['Aanbetaling betaalplan', currencyFormatter.format(downPayment)],
    [
      <>
        Totaal krediet
        <wbr />
        bedrag
      </>,
      currencyFormatter.format(credit),
    ],
    [
      <>
        Termijn
        <wbr />
        bedrag
      </>,
      <>
        {currencyFormatter.format(monthlyPayment)} <span className="text-lowercase">p/m*</span>
      </>,
    ],
    [
      <>
        Slot
        <wbr />
        termijn
      </>,
      currencyFormatter.format(finalPayment),
    ],
    ['Totaal te betalen bedrag', currencyFormatter.format(totalCreditPayment)],
  ]

  return (
    <div className="mb-3">
      {showTableHorizontal && windowSize.innerWidth > breakpoints.md ? (
        <table className="table table-bordered w-100">
          <thead>
            <tr>
              {tableData.map((row, index) => (
                <td className="h5 word-break-normal" key={index}>
                  {row[0]}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {tableData.map((row, index) => (
                <td key={index}>{row[1]}</td>
              ))}
            </tr>
          </tbody>
        </table>
      ) : (
        <table className="table table-bordered table-50-50 w-100">
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <th className="h5 word-break-normal">{row[0]}</th>
                <td>{row[1]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <div className="text-small mt-5 text-muted">
        <p>
          <strong className="font-weight-bold">Details</strong> | Jaarlijkse kostenpercentage:{' '}
          {numberFormatter.format(Number(financialConfig.interest_rate))}% | Debet rentevoet:{' '}
          {numberFormatter.format(Number(financialConfig.interest_rate))} % | Looptijd:{' '}
          {configuration.basic.durationMonths} maanden | Aanbetaling: {downPaymentPercentage}% |
          Slottermijn: {finalPaymentPercentage}%
        </p>

        <OccasionDisclaimer apiRootUrl={apiRootUrl} product={product} />
      </div>
    </div>
  )
}

export default FinanceTable
