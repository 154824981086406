import React from 'react'
import { IColor } from '~src/api/types/configurator'
import { useModel, useRootState } from '~src/hooks/store'
import { currencyFormatter } from '~src/utils/number-formatter'
import { colorPrice } from '~src/utils/price'
import CheckIconWhite from '~svg/checkmark-white.svg'

const Color = ({
  color,
  selected,
  onSelect,
  showPrice = true,
}: {
  color: IColor
  selected: boolean
  showPrice?: boolean
  onSelect: (color: IColor) => void
}): JSX.Element => {
  const model = useModel()
  const {
    api: { editionFinancialData },
    configuration,
    navigation: { financialPlan },
  } = useRootState()

  return (
    <div className="color" onClick={() => onSelect(color)}>
      <div
        className={`color__circle ${selected ? 'color__circle--selected' : ''}`}
        style={{ backgroundColor: `#${color.hex_code}` }}
        title={color.name}
      >
        {color.secondary_hex_code && (
          <div
            className="color__secondary-color"
            style={{ backgroundColor: '#' + color.secondary_hex_code }}
          />
        )}
        {selected && <CheckIconWhite height={16} width={17} />}
      </div>
      {showPrice && (
        <div className="color__label">
          {currencyFormatter.format(
            colorPrice(color, configuration, editionFinancialData, financialPlan, model)
          )}
        </div>
      )}
    </div>
  )
}

export default Color
