import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SpecificationsTable from './SpecificationsTable'
import { IColor, IEdition, IModelProducts } from '~src/api/types/configurator'
import { apiGet } from '~src/api/client'
import Tooltip from '~src/common/Tooltip'
import { currencyFormatter } from '~src/utils/number-formatter'
import Color from '~src/common/Color'
import RemoveIcon from '~svg/remove.svg'
import { removeFromCompareSelection } from '~src/store/comparison/actions'
import { isMobile } from '~src/utils/breakpoints'
import { initialData } from '~src/store/configuration/configurationReducer'
import { useRootState } from '~src/hooks/store'
import { regularTotalPrice } from '~src/utils/price'
import ButtonArrow from '~svg/button-arrow.svg'

const ComparisonPage = () => {
  const {
    comparison: { selectedCarCodes },
    api: { models },
  } = useRootState()
  const [editionsWithModel, setEditionsWithModel] = useState<
    { edition: IEdition; model: IModelProducts }[]
  >([])
  const modelEditions = models.flatMap((model) =>
    model.editions.map((edition) => ({ edition, model }))
  )
  const editions = editionsWithModel.map((i) => i.edition)
  const maxSelections = isMobile() ? 2 : 3

  useEffect(() => {
    Promise.all(
      selectedCarCodes
        .slice(0, maxSelections)
        .map((code) => modelEditions.find((me) => me.edition.code === code))
        .filter(Boolean)
        .map(({ model, edition }) =>
          apiGet<IEdition>(edition.detail_url).then(({ data }) => ({ model, edition: data }))
        )
    ).then((result) => {
      setEditionsWithModel(result)
    })
  }, [selectedCarCodes])

  return (
    <div className="comparison-page">
      <div className="container">
        <div className="row">
          <div className="d-none d-lg-block col-lg-3">
            <h3 className="comparison-page__title mb-0">Modellen vergelijken</h3>
          </div>
          {editionsWithModel.map(({ model, edition }) => (
            <div key={edition.code} className="comparison-item col-6 col-lg-3">
              <EditionCard model={model} edition={edition} />
            </div>
          ))}
        </div>
      </div>

      <SpecificationsTable editions={editions} />
    </div>
  )
}

export default ComparisonPage

const EditionCard = ({ model, edition }: { model: IModelProducts; edition: IEdition }) => {
  const dispatch = useDispatch()
  const [currentColor, setColor] = useState(edition.colors[0])

  return (
    <div className="h-100 d-flex flex-column">
      {model.ribbon && <span className="label label--red label--small">{model.ribbon}</span>}

      {model.product_group !== 'marine' && (
        <figure className="grade-image">
          <img
            src={currentColor.image_url}
            width={360}
            height={180}
            className="img my-3"
            style={{ zIndex: 1 }}
          />
        </figure>
      )}

      <h5 className="comparison-item__title">{edition.name}</h5>

      <div className="comparison-item__price">
        <p>
          Vanaf{' '}
          <span className={`${model.ribbon ? 'text-primary' : ''}`}>
            {currencyFormatter.format(
              regularTotalPrice(
                edition,
                {
                  ...initialData,
                  car: {
                    ...initialData.car,
                    color: currentColor.code,
                  },
                },
                model
              )
            )}
          </span>
        </p>
      </div>

      {edition.discount_label && (
        <div className="editions-carousel-item__deal mt-3">
          {edition.discount_label}
          {edition.discount_tooltip && (
            <span className="ml-2">
              <Tooltip text={edition.discount_tooltip} />
            </span>
          )}
        </div>
      )}

      {model.product_group !== 'marine' && (
        <div className="flex-fill">
          <div className="d-flex flex-wrap">
            {edition.colors.map((color) => (
              <div className="" key={color.code}>
                <Color
                  color={color}
                  selected={color === currentColor}
                  onSelect={setColor}
                  showPrice={false}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-3">
        <a
          href={ctaLink(model, edition, currentColor)}
          className="button button--primary button--full"
        >
          {ctaLabel(model)}
          <ButtonArrow className="icon" />
        </a>
        <button
          className="button button--text button--text-dark button--full comparison-item__remove"
          onClick={() => dispatch(removeFromCompareSelection(edition.code))}
        >
          <RemoveIcon />
          Verwijder {!isMobile() ? 'selectie' : ''}
        </button>
      </div>
    </div>
  )
}

const ctaLink = (model: IModelProducts, edition: IEdition, currentColor: IColor) => {
  switch (model.product_group) {
    case 'car':
      return `/auto/samenstellen/?step=accessories&model=${model.code}&edition=${edition.code}&color=${currentColor.code}`
    case 'marine':
      return `/marine/dealers/`
    case 'motors':
      return `/motoren/dealers/?form_type=proefritaanvraag&model=${model.code}`
  }
}

const ctaLabel = (model: IModelProducts) => {
  switch (model.product_group) {
    case 'car':
      return 'Samenstellen'
    case 'marine':
      return 'Vind een dealer'
    case 'motors':
      return 'Boek een testrit'
  }
}
