import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { apiGet } from '~src/api/client'
import { IFinancialProduct } from '~src/api/types/configurator'
import { useModel, useRootState } from '~src/hooks/store'
import { setFinancialProducts } from '~src/store/api/actions'
import { currencyFormatter } from '~src/utils/number-formatter'
import {
  DEFAULT_DOWN_PAYMENT_PERCENTAGE,
  DEFAULT_FINAL_PAYMENT_PERCENTAGE,
  findFinancialConfig,
  findPaymentPlan,
  getMonthlyCreditPayment,
} from '~src/utils/price'

type Props = {
  financeApiUrl: string
}

const SummaryPrices = ({ financeApiUrl }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const model = useModel()
  const {
    configuration,
    api: { occasion, financialProducts },
  } = useRootState()

  const totalPrice = Number(occasion?.price_online)
  const downPayment =
    configuration.basic.downPayment > 0
      ? configuration.basic.downPayment
      : Math.floor(totalPrice * DEFAULT_DOWN_PAYMENT_PERCENTAGE)

  const credit = totalPrice - downPayment

  const finalPayment =
    configuration.basic.finalPayment > 0
      ? configuration.basic.finalPayment
      : credit * DEFAULT_FINAL_PAYMENT_PERCENTAGE
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup, model)
  const financialConfig = product && findFinancialConfig(credit, product, configuration)

  const monthlyPayment =
    !!financialConfig &&
    getMonthlyCreditPayment(
      credit,
      Number(financialConfig.interest_rate) / 100,
      finalPayment,
      configuration.basic.durationMonths
    )

  useEffect(() => {
    if (!financialProducts.length) {
      apiGet<IFinancialProduct[]>(financeApiUrl).then((response) => {
        dispatch(setFinancialProducts(response.data))
      })
    }
  }, [])

  if (!occasion?.price_online) {
    // We're assuming another block (OccasionOptions) will set the occasion data
    return null
  }

  return (
    <div className="occasion-summary-block__prices mb-4 mt-md-5">
      <div className="price h3 mb-0">{currencyFormatter.format(totalPrice)}&nbsp;</div>
      {monthlyPayment && (
        <>
          of&nbsp;&nbsp;
          <div className="price h3 mb-0">
            {currencyFormatter.format(monthlyPayment)} <span className="text-lowercase">p/m*</span>
          </div>
        </>
      )}
    </div>
  )
}

export default SummaryPrices
