import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { apiGet } from '~src/api/client'
import { postOccasions } from '~src/api/occasions'
import { IFinancialProduct } from '~src/api/types/configurator'
import {
  IFacetChoices,
  IOccasionResponse,
  OccasionBannerProps,
  SortType,
  sortTypes,
} from '~src/api/types/occasion'
import Skeleton from '~src/apps/Configurator/components/Skeleton'
import { IDealerInfo } from '~src/apps/DealerMap/types'
import FilterSummary from '~src/apps/Occasion/FilterSummary'
import Filters from '~src/apps/Occasion/Filters'
import { OccasionBanner } from '~src/apps/Occasion/OccasionBanner'
import SaveFilterStateModal from '~src/apps/Occasion/SaveFilterStateModal'
import { sortLabels } from '~src/apps/Occasion/occasion-label'
import Modal from '~src/common/Modal'
import Pagination from '~src/common/Pagination'
import SelectInput from '~src/common/SelectInput'
import { useModel, useRootState } from '~src/hooks/store'
import useWindowSize from '~src/hooks/useWindowResize'
import { setFinancialProducts } from '~src/store/api/actions'
import { deserializeOccasionState, serializeOccasionState } from '~src/store/url-serializers'
import { breakpoints } from '~src/utils/breakpoints'
import { isObjectEmpty } from '~src/utils/object'
import { findPaymentPlan } from '~src/utils/price'
import CreditWarning from '~svg/credit-warning.svg'
import FilterSvg from '~svg/filter.svg'
import { OccasionDisclaimer } from './OccasionDisclaimer'
import { OccasionCard } from './OccasionCard'

type Props = {
  dealerApiUrl: string
  dealer?: number
  rootUrl?: string
  apiRootUrl?: string
  saveFilterStateApiUrl: string
  financeApiUrl: string
  banner?: OccasionBannerProps
  showBrandName?: boolean
}

const Occasion = ({
  dealerApiUrl,
  dealer,
  rootUrl = '',
  apiRootUrl,
  financeApiUrl,
  banner,
  saveFilterStateApiUrl,
  showBrandName = false,
}: Props): JSX.Element => {
  const dispatch = useDispatch()
  const model = useModel()
  const {
    configuration,
    api: { financialProducts },
  } = useRootState()
  const initialState = useMemo(() => deserializeOccasionState(window.location.search), [])
  const [dealers, setDealers] = useState<IDealerInfo[]>([])
  const [page, setPage] = useState<number>(initialState.page)
  const [data, setData] = useState<IOccasionResponse>(null)
  const [facets, setFacets] = useState<IFacetChoices>(initialState.facets)
  const [sorting, setSorting] = useState<SortType>(initialState.sorting)
  const [loading, setLoading] = useState(true)
  const [filtersModalOpen, setFiltersModalOpen] = useState(false)
  const [saveFilterStateModalOpen, setSaveFilterStateModalOpen] = useState(false)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const ref = useRef<HTMLDivElement>()
  const windowSize = useWindowSize()
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup, model)

  // Limit the result to 11 if there is a banner
  const hasBanner = banner && !isObjectEmpty(banner)
  const showBanner = page === 0 && hasBanner
  const limit = showBanner ? 11 : 12
  const start = page * limit - (hasBanner && page > 0 ? 1 : 0)

  useEffect(() => {
    apiGet<IDealerInfo[]>(dealerApiUrl)
      .then(({ data }) => setDealers(data))
      .catch((error) => {
        // Handle error if necessary
      })
  }, [])

  useEffect(() => {
    if (!financialProducts.length) {
      apiGet<IFinancialProduct[]>(financeApiUrl)
        .then((response) => {
          dispatch(setFinancialProducts(response.data))
        })
        .catch((error) => {
          // Handle error if necessary
        })
    }
  }, [financialProducts])

  useEffect(() => {
    setLoading(true)
    postOccasions(facets, sorting, page, dealer, limit, start)
      .then(setData)
      .catch((error) => {
        // Handle error if necessary
      })
      .finally(() => setLoading(false))

    const queryParams = serializeOccasionState({ page, sorting, facets })
    const url = `${window.location.pathname}${queryParams ? `?${queryParams}` : ''}`
    window.history.replaceState(null, '', url)
  }, [page, facets, sorting])

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [page])

  if (!data) return <div className="occasions-overview-skeleton">Occasions aan het laden...</div>

  const pageCount = Math.ceil(data.count / 12)

  return (
    <>
      <div className="occasion-overview row">
        <div className="col-12 col-lg-3">
          {windowSize.innerWidth < breakpoints.lg ? (
            <Modal
              open={filtersModalOpen}
              onClose={() => setFiltersModalOpen(false)}
              onOpened={() => setFiltersVisible(true)}
            >
              {filtersVisible && (
                <Filters
                  data={data}
                  state={facets}
                  onChange={(f) => {
                    setFacets(f)
                    setPage(0)
                  }}
                  dealers={dealers}
                  fixedDealer={dealer}
                  loading={loading}
                  onMailButtonClick={() => {
                    setFiltersModalOpen(false)
                    setSaveFilterStateModalOpen(true)
                  }}
                />
              )}
            </Modal>
          ) : (
            <Filters
              data={data}
              state={facets}
              onChange={(f) => {
                setFacets(f)
                setPage(0)
              }}
              dealers={dealers}
              fixedDealer={dealer}
              loading={loading}
              onMailButtonClick={() => setSaveFilterStateModalOpen(true)}
            />
          )}
        </div>
        <div className="col-12 col-lg-9">
          <div className="row">
            <div className="col-12">
              <button
                className="product-overview__filter-button button d-lg-none"
                type="button"
                onClick={() => setFiltersModalOpen(true)}
              >
                Filteren
                <FilterSvg className="icon icon--filter" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md d-flex align-items-center">
              <span className="h5 mb-3 mb-md-0">{data.count} Auto&apos;s gevonden</span>
            </div>
            <div className="col-12 col-md-auto">
              <div className="form-group form-inline occasion-overview__sort">
                <label className="input-label mr-3">Sorteren op</label>
                <div className="">
                  <SelectInput
                    options={['', ...sortTypes]}
                    getLabel={(i) => sortLabels(i as SortType)}
                    getValue={(i) => i}
                    onChange={(e) => setSorting(e as SortType)}
                    value={sorting}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <FilterSummary
              facets={facets}
              dealers={dealers}
              onRemove={(id) => setFacets({ ...facets, [id]: undefined })}
              onRemoveAll={() => setFacets({})}
            />
          </div>

          <div className="credit-warning" title="Let op! Geld lenen kost geld">
            <CreditWarning height="30" width="270" />
          </div>

          <div className="row" ref={ref}>
            {loading
              ? Array.from({ length: 12 }, (_, i) => (
                  <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-5" key={i}>
                    <Skeleton height={370} />
                  </div>
                ))
              : data.items.map((car, index) => (
                  <Fragment key={car.id.carId}>
                    {index === 4 && showBanner && (
                      <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-5">
                        <OccasionBanner {...banner} />
                      </div>
                    )}
                    <div className="col-12 col-md-6 col-lg-4 mb-3 mb-md-5">
                      <OccasionCard
                        car={car}
                        dealer={dealers.find((d) => d.dealer_number == Number(car.clientId))}
                        rootUrl={rootUrl}
                        product={product}
                        showBrandName={showBrandName}
                      />
                    </div>
                  </Fragment>
                ))}
          </div>
          <Pagination
            page={page}
            pageCount={pageCount}
            onClickLeft={page > 0 ? () => setPage(page - 1) : null}
            onClickRight={page + 1 < pageCount ? () => setPage(page + 1) : null}
          />
        </div>
        <SaveFilterStateModal
          open={saveFilterStateModalOpen}
          onClose={() => setSaveFilterStateModalOpen(false)}
          onOpened={() => setFiltersModalOpen(false)}
          saveFilterStateApiUrl={saveFilterStateApiUrl}
        />
      </div>
      <div className="text-small mt-5 text-muted">
        <OccasionDisclaimer apiRootUrl={apiRootUrl} product={product} />
      </div>
    </>
  )
}

export default Occasion
