import React from 'react'
import { useDispatch } from 'react-redux'
import { useModel, useRootState } from '~src/hooks/store'
import { setTradeInModalStatus } from '~src/store/trade-in/actions'
import { currencyFormatter } from '~src/utils/number-formatter'
import { findFinancialConfig, findPaymentPlan, getMonthlyCreditPayment } from '~src/utils/price'
import IconRemove from '~svg/remove.svg'

const PaymentPlanSummary = () => {
  const dispatch = useDispatch()
  const model = useModel()
  const {
    configuration,
    api: { occasion, financialProducts },
  } = useRootState()

  const totalPrice = Number(occasion.price_online)
  const credit = totalPrice - configuration.basic.downPayment
  const product =
    financialProducts && findPaymentPlan(financialProducts, configuration.productGroup, model)
  const financialConfig = product && findFinancialConfig(credit, product, configuration)

  const monthlyPayment = getMonthlyCreditPayment(
    credit,
    Number(financialConfig.interest_rate) / 100,
    configuration.basic.finalPayment,
    configuration.basic.durationMonths
  )

  return (
    <div className="occasion-finance-payment-summary trade-in-modal__card">
      <div className="occasion-finance-payment-summary__title">
        <h4 className="mb-2">Suzuki betaalplan</h4>
        <button
          className="occasion-finance-payment-summary__change"
          onClick={() => dispatch(setTradeInModalStatus('flow-4-calculate-monthly-payment'))}
        >
          Wijzigen
        </button>
      </div>

      <p className="mb-3">Betaal in termijnen met de autofinanciering van Suzuki.</p>

      <div>
        <div className="text-small">
          {occasion.brand} {occasion.model} kosten
        </div>

        <h5 className="mb-2">{currencyFormatter.format(totalPrice)}</h5>
      </div>

      <div className="d-md-flex">
        <div className="mr-md-4">
          <div className="text-small">Te financieren bedrag</div>

          <h5 className="mb-2">
            {currencyFormatter.format(totalPrice - configuration.basic.downPayment)}
          </h5>
        </div>

        <div className="mr-md-4">
          <div className="text-small">Looptijd</div>

          <h5 className="mb-2">{configuration.basic.durationMonths} maanden</h5>
        </div>

        <div>
          <div className="text-small">Slottermijn</div>

          <h5 className="mb-2">{currencyFormatter.format(configuration.basic.finalPayment)}</h5>
        </div>
      </div>

      <div>
        <div className="text-small">Suzuki betaalplan</div>

        <h5 className="mb-0">
          {currencyFormatter.format(monthlyPayment)} <span className="text-lowercase">p/m*</span>
        </h5>
      </div>

      <button
        className="financial-step-trade-in__remove-old-car"
        onClick={() => dispatch(setTradeInModalStatus('flow-4-remove-finance'))}
      >
        <IconRemove className="icon--trash" height={16} width={16} />
      </button>
    </div>
  )
}

export default PaymentPlanSummary
